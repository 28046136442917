import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config/config'
import abi from '../assets/abi/abi.json'
import * as s from '../styles/globalStyles'

const ethers = require('ethers')

const HeroContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  text-align: center;
`

const HeroWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`

const Image = styled.img`
  width: 500px;
  border-radius: 20px;
  @media (max-width: 530px) {
    width: 300px;
  }
`

const MintContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: 'Light';
  width: 350px;
  height: 350px;
  display: flex;
  background-color: black;
  box-shadow: rgb(25 19 38 / 70%) 0px 2px 12px -8px,
    rgb(25 19 38 / 5%) 0px 1px 1px;
  border-radius: 20px;
  border: 4px solid gold;
  @media (max-width: 530px) {
    width: 410px;
    height: 410px;
  }
  @media (max-width: 400px) {
    width: 370px;
    height: 370px;
  }
  @media (max-width: 740px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 915px) {
    width: 370px;
    height: 370px;
  }
`

const Button = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 1px;
  align-items: center;
  text-align: center;
  background-color: purple;
  padding: 8px;
  width: 280px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff,
      0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

const Button1 = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 1px;
  align-items: center;
  text-align: center;
  background: url('https://animalove.art/images/OCWOG-p-500.png');
  background-size: cover;
  padding: 8px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff,
      0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

const MintWrapper = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

const ButtonWrapper = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const HeroSection = () => {
  // usestate isConnected bool
  const [isConnected, setIsConnected] = useState(false)
  // usestate defaultAccount null
  const [defaultAccount, setDefaultAccount] = useState(null)
  // usestate mintAmount 1
  const [mintAmount, setMintAmount] = useState(1)
  // usestate supply 0
  const [supply, setSupply] = useState(0)
  // usestate isMinting false
  const [isMinting, setIsMinting] = useState(false)

  // usestate provider null
  const [provider, setProvider] = useState(null)
  // usestate contract null
  const [contract, setContract] = useState(null)
  // usestate signer null
  const [signer, setSigner] = useState(null)
  const [mintState, setMintState] = useState(true)

  const connect = () => {
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString()
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((account) => {
            setIsConnected(true)
            accountChangedHandler(account[0])
            getSupply()
          })
      }
    } else {
      setIsConnected(false)
    }
  }

  const accountChangedHandler = (account) => {
    setDefaultAccount(account)
    updateEthers()
  }

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum)
    setProvider(tempProvider)

    let tempSigner = tempProvider.getSigner()
    setSigner(tempSigner)

    let tempContract = new ethers.Contract(config.address, abi, tempSigner)
    setContract(tempContract)
  }

  const mint = async () => {
    let totalCost = mintAmount * config.cost
    let totalGas = mintAmount * config.gasLimit
    setIsMinting(true)
    await contract
      .mint(mintAmount, {
        gasLimit: totalGas,
        value: ethers.utils.parseEther(totalCost.toString()),
      })
      .then((tx) => {
        tx.wait().then(() => {
          setIsMinting(false)
          window.location.reload()
        })
      })
      .catch((err) => {
        console.log(err)
        setIsMinting(false)
      })
  }

  const getSupply = async () => {
    let supply = await contract.totalSupply()
    console.log(supply)
    setSupply(supply.toString())
    if (supply >= config.maxSupply) {
      setMintState(false)
    }
  }

  useEffect(() => {
    updateEthers()
  }, [])

  return (
    <HeroContainer>
      <Title>
        <h1>Devoted Dawgs Minting</h1>
      </Title>
      <s.TextDescription
        style={{
          textAlign: 'center',
        }}
      >
        <h3>
          Devoted Dawgs are here to end the cat and mouse chase with a new twist
          to the animal kingdom. Enjoy the beautiful friends and their
          offsprings in the upcoming collection.
        </h3>
      </s.TextDescription>
      <HeroWrapper>
        <MintContainer>
          <MintWrapper>
            {!isConnected ? (
              <>
                <Button1 onClick={connect}></Button1>
                <h2>Connect Wallet</h2>
              </>
            ) : (
              <>
                {mintState ? (
                  <>
                    <h5>
                      {' '}
                      Total Minted {supply} / {config.maxSupply}
                    </h5>

                    <ButtonWrapper>
                      <Button
                        onClick={mint}
                        style={{
                          width: '140px',
                        }}
                      >
                        <h2>MINT Now</h2>
                      </Button>
                    </ButtonWrapper>
                    <h5>Minting Cost {config.cost} FLR </h5>

                    <p>
                      Connected to:{' '}
                      {defaultAccount.slice(0, 6) +
                        '...' +
                        defaultAccount.slice(-4)}
                    </p>
                    <p>50% mints FLR goes to community pool</p>
                  </>
                ) : (
                  <>
                    <h1 style={{ marginTop: '10%' }}>Mint Completed!</h1>
                  </>
                )}
              </>
            )}
          </MintWrapper>
        </MintContainer>
      </HeroWrapper>
      <s.TextDescription
        style={{
          textAlign: 'center',
          color: '#white',
        }}
      >
        <br />
      </s.TextDescription>
      <s.SpacerSmall />
      <s.TextDescription
        style={{
          textAlign: 'center',
          color: '#ffffff;',
        }}
      >
        <h3>
          {' '}
          Please make sure you are connected to the right network (Flare
          Mainnet) and the correct address.
          <br />{' '}
        </h3>
      </s.TextDescription>
    </HeroContainer>
  )
}

export default HeroSection
