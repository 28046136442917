import "./App.css";
import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";
import "./fonts/RoyalKing-Free.ttf"


function App() {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundImage: `url("/assets/images/bg2.png")`, 
        backgroundPosition: 'center',

        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
    

       
      }}
    >
      <Navbar />
      <HeroSection />
    </div>
  );
}

export default App;
