module.exports = {
    name: "The Devoted Dawgs",
    address: "0x39F350A456554EdF3A1AA9D8fe9615C2118ef1D9",
    maxMint: 1,
    chainId: 14,
    network: "Flare",
    openseaLink: "https://www.sparklesnft.com/collection/flare/devoteddawgs/",
	cost: 1500,
	Care: 4000,
    gasLimit: 400000,
    maxSupply: 600,
    symbol: "FLR",
};
