import React from "react";

const Navbar = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 20px",
        display: "flex",
      }}
    >
   
    </div>
  );
};

export default Navbar;
