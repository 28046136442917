import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/RoyalKing-Free.ttf';
import './fonts/Trajan Pro Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
